<script setup lang="ts">
import CellLoading from '/~/extensions/bank-file-upload/components/common/cell-loading.vue'
import BusinessNumberInput from '/~/extensions/bank-file-upload/components/missing-details/list/item/business-number-input.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'

export type Row = {
  accountName: string
  bsb: string
  accountNumber: string
}

withDefaults(
  defineProps<{
    row: Row
    loading: boolean
    isCheckingAccess?: boolean
  }>(),
  {
    isCheckingAccess: false,
  }
)

const emit = defineEmits<{
  addPayee: () => void
  validated: () => void
  clear: () => void
}>()

const { formatBsb } = useLocalization()
</script>

<template>
  <div class="text-sm">
    <div class="w-72 px-5">
      <cell-loading :loading="loading">
        {{ row.accountName }}
      </cell-loading>
    </div>
    <div class="space-x-0.5 px-5">
      <cell-loading :loading="loading">{{ formatBsb(row.bsb) }},</cell-loading>
      <cell-loading :loading="loading">
        {{ row.accountNumber }}
      </cell-loading>
    </div>
    <div class="ml-auto w-80 px-5">
      <cell-loading :loading="loading" block>
        <business-number-input
          :disabled="row.status === 'creating' || row.status === 'created'"
          :abn="row.abn"
          :entity-name="row.entityName"
          allow-clear
          @validated="emit('validated', $event)"
          @cleared="emit('clear')"
        />
      </cell-loading>
    </div>
    <div class="w-60 px-5 text-center">
      <cell-loading :loading="loading" block width="w-28">
        <div
          v-if="row.status === 'created'"
          class="flex items-center justify-center space-x-2 font-semibold text-emerald-700"
        >
          <span>Submitted for approval</span>
          <base-icon :size="20" svg="heroicons/outline/check" />
        </div>
        <base-button
          v-else
          :disabled="!row.abn"
          :loading="row.status === 'creating' || isCheckingAccess"
          look="outlined-color"
          class="!h-9 w-28 !rounded !text-sm transition-all"
          @click="emit('addPayee')"
        >
          + Add payee
        </base-button>
      </cell-loading>
    </div>
  </div>
</template>
